/**
 * @file standalone/content_block.scss
 *
 * \brief Library file - shared styles for content blocks
 *
 */

@import '../theme-bootstrap';

.content-block {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 0;
  &[data-clickable],
  [data-clickable] {
    cursor: pointer;
  }
  b,
  strong {
    font-weight: bold;
  }
  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
  }
  &__content-wrapper {
    width: 100%;
    height: 100%;
    &--positioned {
      position: absolute;
      z-index: 3;
      top: 0;
      left: 0;
    }
  }
  &__line {
    // make sure we do not pick up flex alignment rules
    display: block;
    padding-bottom: 12px; // @todo we shoulod remove this - too big of an assumption to be generic.
    // compensate for the WYSIWYG adding extra paragraphs
    p {
      margin: 0;
      padding: 0;
    }
    // Typography defaults when none are selected in cms
    // Defaults for links and heading elements
    &--eyebrow {
      .style--bare {
        @include title--h5;
      }
    }
    &--headline {
      .style--bare {
        @include title--h2;
      }
    }
    &--content {
      .style--bare {
        @include body-text;
      }
    }
  }
  &__links {
    display: block;
    min-height: 1.5em;
    padding-bottom: 12px;
    &--2up {
      > div:first-of-type {
        margin-bottom: 20px;
      }
    }
  }
  &__link-wrapper {
    display: contents;
    &--column {
      display: flex;
      flex-direction: column;
      align-items: inherit;
    }
    &--row {
      display: flex;
      flex-direction: row;
      align-items: inherit;
    }
    &--new-style {
      .content-block__link a {
        min-width: 187.5px;
        @include breakpoint($landscape-up) {
          min-width: 163px;
        }
      }
    }
  }
  // Adding for the cases where we are not using mantle media.
  // We need to make sure we fill containers.
  &__picture {
    &-media-asset {
      width: 100%;
      height: 100%;
      img,
      picture,
      video {
        max-width: 100%;
        width: 100%;
        height: auto;
      }
    }
  }
  // Editorial blocks that rely on WYSIWYG styling need more defaults
  &.split-text, // @todo probably move to split text only...
  // &.text-tout, // @todo continue refining - make this file more generic and move out custom stuff
  &.basic-tout {
    &__media-wrapper {
      height: auto;
    }
    &__content {
      height: auto;
      padding: 0 52px; // @todo we have vertical padding inpout for this - can we remove?
      @include breakpoint($landscape-up) {
        padding: 0 40px; // @todo we have vertical padding inpout for this - can we remove?
      }
      .elc-pick-up-in-store-modal & {
        padding: 0;
        @include breakpoint($medium-up) {
          padding: 20px 40px 0 !important;
        }
      }
      .content-block {
        &:empty {
          display: none;
        }
      }
    }
    .basic-tout {
      &__content-over-media {
        position: absolute;
        top: 0;
        bottom: 0;
        #{$ldirection}: 0;
        #{$rdirection}: 0;
        &-wrapper {
          width: 100%;
        }
      }
      &__wrapper {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
      &__content-wrapper {
        &.content-block__content-wrapper {
          justify-content: center;
        }
      }
      &__content {
        &.content-block__content {
          flex: 0 1 auto;
          padding-top: 12px;
          @include breakpoint($landscape-up) {
            padding-top: 0;
          }
        }
      }
    }
    .content-block {
      &__links {
        @include flex-row-swap-direction();
        display: flex;
        justify-content: center;
        padding-bottom: 0;
      }
      &__line {
        &--link {
          justify-content: center;
          display: flex;
          max-width: 100%;
          padding-bottom: 0;
          .elc-media-asset {
            &.elc-link {
              height: auto;
              line-height: 16px;
              padding: 0;
              font-size: 12px;
              letter-spacing: get-letter-spacing(170);
              &:focus-visible {
                outline-offset: 1px;
              }
            }
          }
        }
        &--link:nth-of-type(2) {
          padding-#{$ldirection}: 24px;
        }
      }
    }
    .mobile-flex-hidden {
      display: none;
      @include breakpoint($landscape-up) {
        display: flex;
      }
    }
    .pc-hidden {
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    .align-right {
      .content-block__links {
        @include flex-align-swap-direction(align-self, flex-end);
      }
      &.basic-tout__content {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
    .align-left {
      .content-block__links {
        @include flex-align-swap-direction(align-self, flex-start);
      }
      &.basic-tout__content {
        padding-#{$ldirection}: 0;
        padding-#{$rdirection}: 0;
      }
    }
  }
  &.basic-tout {
    .content-block__line {
      // @todo test and add padding rules per headline default
      h1 {
        @include title--h1;
        margin-bottom: 24px;
      }
      h2 {
        @include title--h2;
        margin-bottom: 1em;
      }
      h3 {
        @include title--h3;
        margin-bottom: 1em;
      }
      h4 {
        @include title--h4;
        margin-bottom: 1em;
      }
      h5 {
        @include title--h5;
        margin-bottom: 1em;
      }
      p {
        margin-bottom: 1em;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.lazyload {
  visibility: hidden;
}
